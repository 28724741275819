.header {
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .headerContainer {
    padding: 15px 10%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    background-image: url('../public/assets/signup_background.png');
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;

    .logoContainer {
      color: var(--white-color);
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      font-size: 25px;
      text-transform: uppercase;
      &:first-child {
        font-weight: 300;
      }

      span {
        font-weight: 800;
      }
    }
  }

  .navBar {
    color: var(--white-color);
    display: flex;
    align-items: center;
    gap: 40px;
    font-size: 16px;
    font-weight: 600;
  }
  .dashboardBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    color: var(--white-color);
    background: var(--primary-button);
    width: 140px;
    height: 40px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
    font-family: inherit;
  }
}

.layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: space-between;
  align-items: center;
  gap: 20px;
  min-height: 600px;

  .not_found {
    font-size: 40px;
    font-weight: 600;
    letter-spacing: 2px;
  }

  .back_row {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    .back_text {
      border-bottom: 1px solid #000000;
      font-size: 20px;
      font-weight: 500;
    }
  }
}

@media screen and (max-width: 1200px) {
  .header {
    .headerContainer {
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }
  }
}

@media screen and (max-width: 768px) {
  .header {
    .headerContainer {
      padding: 15px 0;
      flex-direction: row;
      position: relative;
      gap: 15px;

      .logoContainer {
        padding-left: 20px;
        font-size: 22px;
        gap: 5px;

        .logo {
          width: 50px;
        }
      }
    }
  }

  .layout {
    .not_found {
      font-size: 30px;
      font-weight: 600;
      letter-spacing: 1px;
    }

    .back_row {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      cursor: pointer;

      .back_text {
        border-bottom: 1px solid #000000;
        font-size: 17px;
        font-weight: 500;
      }
    }
  }
}

@media screen and (max-height: 575px) {
  .header {
    padding-bottom: 100px;
  }
}
